import React from "react";
import Gallery from "components/ImageGallery";
import MKBox from "components/MKBox";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";

// Routes
import routes from "common/routes";
import footerRoutes from "common/footer-routes";

const GalleryPage = () => {
  return (
    <>
      <DefaultNavbar routes={routes} sticky/>
      <Gallery/>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes}/>
      </MKBox>
    </>
  );
};

export default GalleryPage;
