/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
     inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items.
  8. The `description` key is used to define the description of a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
     you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
// import Icon from "@mui/material/Icon";

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";

import InformationIcon from "assets/images/navbar/Информация.png";
import GalleryIcon from "assets/images/navbar/Галерея.png";
import AboutUsIcon from "assets/images/navbar/О нас.png";
import ServicesIcon from "assets/images/navbar/Услуги.png";
import NewsIcon from "assets/images/navbar/Новости.png";
import ContactUsIcon from "assets/images/navbar/Связаться.png";
import EmployeeIcon from "assets/images/navbar/Сотрудники.png";

// Pages
import Services from "app/services/page";
import InfoPage from "app/info/page";
import ContactUs from "app/contact-us/page";
import SignIn from "app/sign-in/page";
import AboutUs from "app/about-us/page";
import Gallery from "app/gallery/page";
import Employee from "app/employee";
import News from "app/news/page";

const iconStyle = {
  width: "15px", // Укажите желаемую ширину
  height: "15px", // Укажите желаемую высоту
};
const routes = [
  {
    name: "О нас",
    icon: <img src={AboutUsIcon} style={iconStyle} />,
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "Информация",
    icon: <img src={InformationIcon} style={iconStyle} />,
    route: "/info",
    component: <InfoPage />,
  },
  {
    name: "Услуги",
    icon: <img src={ServicesIcon} style={iconStyle} />,
    route: "/services",
    component: <Services />,
  },
  {
    name: "Галерея",
    icon: <img src={GalleryIcon} style={iconStyle} />,
    route: "/gallery",
    component: <Gallery />,
  },
  {
    icon: <img src={NewsIcon} style={iconStyle} />,
    name: "Новости",
    route: "/news",
    component: <News />,
  },
  {
    name: "Связаться",
    icon: <img src={ContactUsIcon} style={iconStyle} />,
    route: "/contact-us",
    component: <ContactUs />,
  },
  {
    name: "Сотрудники",
    icon: <img src={EmployeeIcon} style={iconStyle} />,
    route: "/employee",
    component: <Employee />,
  },
  {
    type: "external",
    name: "Войти",
    route: "/sign-in",
    label: "Войти",
    color: "info",
    component: <SignIn />,
  },
];

export default routes;
